import React from "react";

function Footer() {
  return (
    <div>
      <br />
      This site is made by gongboo
      <a href="/info" style={{ color: "gray" }}>
        ⓘ
      </a>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default Footer;
