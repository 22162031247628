import React, { useState, useRef, useEffect, useMemo } from "react";

import { ChromePicker } from "react-color";
import "draft-js/dist/Draft.css";
import "./App.css";
import { TTF_KNIT_CHARACTERS, TTF_CROCHET_CHARACTERS } from "./TTF";
import html2canvas from "html2canvas";
import useWindowSize from "./useWindowSize";
import { IntlProvider, FormattedMessage } from "react-intl";
import messages from "./messages";
import Collapsible from "react-collapsible";
import "./tiptap.css";
import { Editor, Extension } from "@tiptap/core";
import Footer from "./Footer";

import {
  EditorProvider,
  FloatingMenu,
  BubbleMenu,
  useCurrentEditor,
  useEditor,
  EditorContent,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import { FontSize } from "tiptap-extension-font-size";

const fontFamilyString =
  "'Fontello', 'Noto Sans', 'Noto Sans KR', 'Noto Sans JP', sans-serif";
function TextKnitTyper() {
  const userLanguage = navigator.language.split("-")[0];

  const locale =
    userLanguage === "ko" || userLanguage === "ja" ? userLanguage : "en";
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const [activeTab, setActiveTab] = useState("knit");

  const [activeCrochetTab, setActiveCrochetTab] = useState(0);

  const handleImageSave = () => {
    const editorContainer = document.querySelector(".editor-container");

    // Scale 옵션을 추가하여 해상도를 높임
    html2canvas(editorContainer, { scale: 10 }).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png", 1.0); // 화질을 100%로 설정
      link.download = "textknittyper.png";
      link.click();
    });
  };

  const CustomHighlight = Highlight.extend({
    addAttributes() {
      return {
        color: {
          default: null,
          parseHTML: (element) => element.style.backgroundColor,
          renderHTML: (attributes) => {
            if (!attributes.color) {
              return {};
            }
            return {
              style: `background-color: ${attributes.color}`,
            };
          },
        },
      };
    },
    priority: 1000,
  });

  const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
    }),
    CustomHighlight,
    TextAlign.configure({
      // types: ["heading", "paragraph"],
      defaultAlignment: "left",
    }),
    FontSize,
  ];

  const content = ``;
  const editor = useEditor({
    extensions: extensions,
    content: content,
  });

  //---
  const renderCharacters = () => {
    if (activeTab === "knit") {
      return (
        <div className="ttf-characters">
          {TTF_KNIT_CHARACTERS.map((icon, index) => (
            <button
              key={index}
              className="icon-button"
              onMouseDown={(e) => {
                e.preventDefault();
                // insertSpecialCharacter(char.label);
                editor.chain().focus().insertContent(icon.label).run();
              }}
              title={icon.name}
            >
              {icon.label}
            </button>
          ))}
        </div>
      );
    } else if (activeTab === "crochet") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="ttf-characters">
            {TTF_CROCHET_CHARACTERS.map((icon, index) => {
              return (
                <button
                  key={index}
                  className="icon-button"
                  onClick={() => setActiveCrochetTab(index)}
                  style={{
                    backgroundColor:
                      index === activeCrochetTab ? "#b0c4de" : "gray",
                  }}
                  // title={icon.name}
                >
                  {icon.label}
                </button>
              );
            })}
          </div>
          <div className="ttf-characters">
            {TTF_CROCHET_CHARACTERS[activeCrochetTab].children?.map(
              (icon, index) => {
                return (
                  <button
                    key={index}
                    className="icon-button"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      editor.chain().focus().insertContent(icon.label).run();
                    }}
                    // title={icon.name}
                  >
                    {icon.label}
                  </button>
                );
              }
            )}
          </div>
        </div>
      );
    }
  };

  const setFontSize = (size) => {
    editor.chain().focus().setFontSize(size).run();
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="App">
        <h1>TEXT KNIT TYPER</h1>
        {/* <MenuBar
          editor={editor}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeCrochetTab={activeCrochetTab}
          setActiveCrochetTab={setActiveCrochetTab}
        />{" "} */}
        <div className="control-group">
          <div className="button-group">
            <button
              onPointerDown={(event) => {
                console.log("Before focus:", editor.isFocused);
                event.preventDefault(); // 기본 동작 방지
                event.stopPropagation(); // 이벤트 전파 방지
                editor.chain().focus().toggleBold().run();
                console.log("After focus:", editor.isFocused);
              }}
              className={editor.isActive("bold") ? "is-active" : ""}
            >
              <img src="format_bold.svg" alt="Icon" width="24" height="24" />
            </button>
            <button
              onPointerDown={() => editor.chain().focus().toggleItalic().run()}
              className={editor.isActive("italic") ? "is-active" : ""}
            >
              <img src="format_italic.svg" alt="Icon" width="24" height="24" />
            </button>
            <button
              onPointerDown={() => editor.chain().focus().toggleStrike().run()}
              className={editor.isActive("strike") ? "is-active" : ""}
            >
              <img src="format_strike.svg" alt="Icon" width="24" height="24" />
            </button>
            <button
              onPointerDown={() => editor.chain().focus().unsetAllMarks().run()}
            >
              <img src="format_clear.svg" alt="Icon" width="24" height="24" />
            </button>
            <button
              onPointerDown={() =>
                editor.chain().focus().toggleBulletList().run()
              }
              className={editor.isActive("bulletList") ? "is-active" : ""}
            >
              <img
                src="format_list_bulleted.svg"
                alt="Icon"
                width="24"
                height="24"
              />
            </button>
            <button
              onPointerDown={() =>
                editor.chain().focus().toggleOrderedList().run()
              }
              className={editor.isActive("orderedList") ? "is-active" : ""}
            >
              <img
                src="format_list_numbered.svg"
                alt="Icon"
                width="24"
                height="24"
              />
            </button>
            <div className="div-box">
              <img
                src="format_color_text.svg"
                alt="Icon"
                width="24"
                height="24"
              />
              {/* <ColorPickerComponent editor={editor} /> */}
              <input
                type="color"
                onChange={(event) => {
                  // event.preventDefault(); // 기본 동작 방지
                  // event.stopPropagation(); // 이벤트 전파 방지
                  editor.chain().focus().setColor(event.target.value).run();
                }}
                value={editor.getAttributes("textStyle").color || "#000000"}
                data-testid="fontColor"
                style={{ border: "none", padding: 0 }}
              />
              {/* <ColorPickerComponent editor={editor} /> */}
            </div>
            <div className="div-box">
              <img
                src="format_ink_highlighter.svg"
                alt="Icon"
                width="24"
                height="24"
              />
              <input
                type="color"
                onChange={(event) =>
                  editor
                    .chain()
                    .focus()
                    .setMark("highlight", { color: event.target.value })
                    .run()
                }
                value={editor.getAttributes("highlight").color || "#ffffff"}
                data-testid="backgroundColor"
                style={{ border: "none", padding: 0 }}
              />{" "}
            </div>{" "}
            <select
              onChange={(event) => {
                const value = event.target.value;
                if (value === "none") {
                  editor.chain().focus().setParagraph().run(); // 헤딩을 제거하고 문단으로 변경
                } else {
                  const level = parseInt(value, 10);
                  editor.chain().focus().toggleHeading({ level }).run(); // 선택한 헤딩 레벨 적용
                }
              }}
              value={
                editor.isActive("paragraph")
                  ? "none"
                  : [1, 2, 3, 4, 5, 6].find((level) =>
                      editor.isActive("heading", { level })
                    ) || ""
              }
            >
              <option value="none">title</option>
              <option value="1">H1</option>
              <option value="2">H2</option>
              <option value="3">H3</option>
              <option value="4">H4</option>
              <option value="5">H5</option>
              <option value="6">H6</option>
            </select>
            <select
              onChange={(event) => {
                const fontSize = event.target.value;
                setFontSize(fontSize); // 선택한 글자 크기 적용
              }}
              value={
                editor.getAttributes("textStyle").fontSize || "24px" // 현재 선택된 글자 크기 반영
              }
            >
              <option value="12px">12px</option>
              <option value="16px">16px</option>
              <option value="24px">24px</option>
              <option value="36px">36px</option>
              <option value="48px">48px</option>
            </select>
            <button onPointerDown={() => editor.chain().focus().undo().run()}>
              <img src="undo.svg" alt="Icon" width="24" height="24" />
            </button>
            <button onPointerDown={() => editor.chain().focus().redo().run()}>
              <img src="redo.svg" alt="Icon" width="24" height="24" />
            </button>
          </div>{" "}
          <div className="centered-container">
            <div style={{ display: "flex" }}>
              <div className="tab-menu">
                <button
                  onPointerDown={() => setActiveTab("knit")}
                  className={activeTab === "knit" ? "active" : ""}
                >
                  <FormattedMessage id="knit" />
                </button>
                <button
                  onPointerDown={() => setActiveTab("crochet")}
                  className={activeTab === "crochet" ? "active" : ""}
                >
                  <FormattedMessage id="crochet" />
                </button>
              </div>

              <div
                className="ttf-characters-container"
                style={{ width: windowWidth * 0.8 }}
              >
                {renderCharacters()}
              </div>
            </div>
          </div>
        </div>

        <div
          className="editor-wrapper"
          style={{
            justifyContent: windowWidth > 794 ? "center" : "flex-start",
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          {" "}
          <div className="editor-container tiptap">
            <EditorContent editor={editor} />
          </div>
        </div>
        <div className="button-container">
          <button onClick={handleImageSave}>
            <FormattedMessage id="saveImage" />
          </button>
        </div>
      </div>
      <Footer></Footer>
    </IntlProvider>
  );
}

export default TextKnitTyper;
