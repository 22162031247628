import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";

import Info from "./Info";

import TextKnitTyper from "./TextKnitTyper";

function Router() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <TextKnitTyper></TextKnitTyper>,
    },

    {
      path: "/info",
      element: <Info />,
    },
    {
      path: "/*",
      element: <div>not found</div>,
    },
  ]);
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
}
export default Router;
