// TTF 파일의 특수 문자 매핑 (예시)
const TTF_KNIT_CHARACTERS = [
  { label: "\uE85A", name: "Icon 1" },
  { label: "\uE800", name: "Icon 1" },
  { label: "\uE801", name: "Icon 2" },
  { label: "\uE802", name: "Icon 3" },
  { label: "\uE803", name: "Icon 4" },
  { label: "\uE804", name: "Icon 5" },
  { label: "\uE805", name: "Icon 6" },
  { label: "\uE806", name: "Icon 7" },
  { label: "\uE807", name: "Icon 8" },
  { label: "\uE808", name: "Icon 9" },
  { label: "\uE809", name: "Icon 10" },
  { label: "\uE80A", name: "Icon 11" },
  { label: "\uE80B", name: "Icon 12" },
  { label: "\uE80C", name: "Icon 13" },
  { label: "\uE80D", name: "Icon 14" },
  { label: "\uE80E", name: "Icon 15" },
  { label: "\uE80F", name: "Icon 16" },
  { label: "\uE810", name: "Icon 17" },
  { label: "\uE811", name: "Icon 18" },
  { label: "\uE812", name: "Icon 19" },
  { label: "\uE813", name: "Icon 20" },
  { label: "\uE814", name: "Icon 21" },
  { label: "\uE815", name: "Icon 22" },
  { label: "\uE816", name: "Icon 23" },
  { label: "\uE817", name: "Icon 24" },
  { label: "\uE818", name: "Icon 25" },
  { label: "\uE819", name: "Icon 26" },
  { label: "\uE81A", name: "Icon 27" },
  { label: "\uE81B", name: "Icon 28" },
  { label: "\uE81C", name: "Icon 29" },
  { label: "\uE81D", name: "Icon 30" },
];

const TTF_CROCHET_CHARACTERS = [
  {
    label: "\uEA00",
    name: "Icon 31",
    children: [
      { label: "\uEA00", name: "Icon 31" },
      { label: "\uEA01", name: "Icon 32" },
      { label: "\uEA02", name: "Icon 33" },
      { label: "\uEA03", name: "Icon 34" },
      { label: "\uEA04", name: "Icon 35" },
      { label: "\uEA05", name: "Icon 36" },
      { label: "\uEA06", name: "Icon 37" },
      { label: "\uEA07", name: "Icon 38" },
      { label: "\uEA08", name: "Icon 39" },
      { label: "\uEA09", name: "Icon 40" },
      { label: "\uEA30", name: "Icon 79" },
      { label: "\uEA31", name: "Icon 80" },
      { label: "\uEA32", name: "Icon 81" },
      { label: "\uEA33", name: "Icon 82" },
      { label: "\uEA38", name: "Icon 87" },
    ],
  },
  {
    label: "\uEA0A",
    name: "Icon 41",
    children: [
      { label: "\uEA0A", name: "Icon 41" },
      { label: "\uEA0B", name: "Icon 42" },
      { label: "\uEA0C", name: "Icon 43" },
      { label: "\uEA0D", name: "Icon 44" },
      { label: "\uEA0E", name: "Icon 45" },
      { label: "\uEA0F", name: "Icon 46" },
      { label: "\uEA10", name: "Icon 47" },
      { label: "\uEA11", name: "Icon 48" },
      { label: "\uEA12", name: "Icon 49" },
      { label: "\uEA13", name: "Icon 50" },
      { label: "\uEA34", name: "Icon 83" },
      { label: "\uEA35", name: "Icon 84" },
      { label: "\uEA36", name: "Icon 85" },
      { label: "\uEA37", name: "Icon 86" },
      { label: "\uEA39", name: "Icon 88" },
    ],
  },
  {
    label: "\uEA14",
    name: "Icon 51",
    children: [
      { label: "\uEA14", name: "Icon 51" },
      { label: "\uEA15", name: "Icon 52" },
      { label: "\uEA16", name: "Icon 53" },
      { label: "\uEA17", name: "Icon 54" },
      { label: "\uEA18", name: "Icon 55" },
      { label: "\uEA19", name: "Icon 56" },
      { label: "\uEA1A", name: "Icon 57" },
      { label: "\uEA1B", name: "Icon 58" },
      { label: "\uEA1C", name: "Icon 59" },
      { label: "\uEA1D", name: "Icon 60" },
    ],
  },
  {
    label: "\uEA1E",
    name: "Icon 61",
    children: [
      { label: "\uEA1E", name: "Icon 61" },
      { label: "\uEA1F", name: "Icon 62" },
      { label: "\uEA20", name: "Icon 63" },
      { label: "\uEA21", name: "Icon 64" },
    ],
  },
  {
    label: "\uEA22",
    name: "Icon 65",
    children: [
      { label: "\uEA22", name: "Icon 65" },
      { label: "\uEA23", name: "Icon 66" },
      { label: "\uEA24", name: "Icon 67" },
      { label: "\uEA25", name: "Icon 68" },
    ],
  },

  {
    label: "\uEA27",
    name: "Icon 70",
    children: [
      { label: "\uEA27", name: "Icon 70" },
      { label: "\uEA28", name: "Icon 71" },
      { label: "\uEA29", name: "Icon 72" },
      { label: "\uEA2A", name: "Icon 73" },
      { label: "\uE858", name: "Icon 72" },
      { label: "\uE859", name: "Icon 73" },
    ],
  },
  {
    label: "\uEA2B",
    name: "Icon 74",
    children: [
      { label: "\uEA2B", name: "Icon 74" },
      { label: "\uEA2C", name: "Icon 75" },
      { label: "\uEA2D", name: "Icon 76" },
    ],
  },
  {
    label: "\uEA2E",
    name: "Icon 77",
    children: [
      { label: "\uEA2E", name: "Icon 77" },
      { label: "\uEA2F", name: "Icon 78" },
    ],
  },
  {
    label: "\uEA26",
    name: "Icon 69",
    children: [{ label: "\uEA26", name: "Icon 69" }],
  },
];

export { TTF_KNIT_CHARACTERS, TTF_CROCHET_CHARACTERS };
