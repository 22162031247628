const messages = {
  en: {
    bold: "Bold",
    italic: "Italic",
    underline: "Underline",
    textcolor: "Text Color",
    highlight: "Highlight",
    knit: "Knit",
    crochet: "Crochet",
    saveImage: "Save Image",
    info_txt:
      "This project is for creating knitting pattern in WYSIWYG editor.",
  },
  ko: {
    bold: "굵게",
    italic: "기울이기",
    underline: "밑줄",
    textcolor: "텍스트 색상",
    highlight: "하이라이트",
    knit: "대바늘",
    crochet: "코바늘",
    saveImage: "이미지 저장",
    info_txt:
      "This project is for creating knitting pattern in WYSIWYG editor.",
  },
  ja: {
    bold: "太字",
    italic: "斜体",
    underline: "下線",
    textcolor: "文字色",
    highlight: "ハイライト",
    knit: "手編み",
    crochet: "かぎ針編み",
    saveImage: "画像を保存",
    info_txt:
      "This project is for creating knitting pattern in WYSIWYG editor.",
  },
};

export default messages;
