import React from "react";
import { useNavigate } from "react-router-dom";
import messages from "./messages";
import { FormattedMessage, IntlProvider } from "react-intl";

function Info() {
  const navigate = useNavigate();
  const userLanguage = navigator.language.split("-")[0];
  const locale =
    userLanguage === "ko" || userLanguage === "ja" ? userLanguage : "en";

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <h1>TEXT KNIT TYPER</h1>
      <div>
        <FormattedMessage id="info_txt" />
      </div>
      <div>email: gongboolearn@gmail.com</div>
      <button
        onClick={() => {
          navigate("/");
        }}
      >
        go back to main page
      </button>
    </IntlProvider>
  );
}

export default Info;
